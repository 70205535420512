import * as React from "react";
import Icon from "@hyperlocal/vital-icons";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { ptBR } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
import { twMerge } from "tailwind-merge";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const dateSelected = Array.isArray(props.selected)
    ? props.selected[0]
    : props.selected;

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={twMerge("z-[9998] p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-neutral-darkest font-bold rounded-md w-8 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: twMerge(
          "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-primary-main [&:has([aria-selected].day-outside)]:bg-primary-main/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
          props.mode === "range"
            ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
            : "[&:has([aria-selected])]:rounded-md",
        ),
        day: "h-8 w-8 p-0 font-normal rounded-sm aria-selected:opacity-100 hover:bg-primary-lighter disabled:cursor-not-allowed disabled:hover:cursor-not-allowed disabled:bg-transparent",
        day_range_start: "day-range-start",
        day_range_end: "day-range-end",
        day_selected:
          "hover:bg-primary-main hover:text-primary-light focus:bg-primary-main focus:text-primary-light rounded-sm bg-primary-dark text-white",
        day_outside:
          "day-outside text-neutral-light aria-selected:bg-primary-light/50 aria-selected:text-white disabled:cursor-not-allowed",
        day_disabled:
          "text-neutral-light opacity-50 cursor-not-allowed disabled:hover:cursor-not-allowed",
        day_range_middle:
          "aria-selected:bg-primary-light aria-selected:text-primary-light",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        DayContent: (props) => (
          <PopoverPrimitive.Close asChild>
            <button className="flex h-full w-full items-center justify-center">
              {props.date.getDate()}
            </button>
          </PopoverPrimitive.Close>
        ),
        IconLeft: ({ className, ...props }) => (
          <Icon
            name="ArrowNoLineLeft"
            fill="currentColor"
            className={twMerge("z-[51] size-6 text-black", className)}
            {...props}
          />
        ),
        IconRight: ({ className, ...props }) => (
          <Icon
            name="ArrowNoLineRigh"
            fill="currentColor"
            className={twMerge("z-[51] size-6 text-black", className)}
            {...props}
          />
        ),
      }}
      defaultMonth={dateSelected as Date}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

const PopoverRoot = ({ children }: { children: React.ReactNode }) => (
  <PopoverPrimitive.Root>{children}</PopoverPrimitive.Root>
);

const PopoverContent = (props: CalendarProps) => {
  return (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        align="center"
        className="z-[9999] border bg-neutral-white p-1 text-neutral-darkest shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        style={{
          pointerEvents: "auto",
        }}
      >
        <Calendar {...props} locale={ptBR} />
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  );
};
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const DatePickerTrigger = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>
>((props, ref) => <PopoverPrimitive.Trigger ref={ref} {...props} />);
DatePickerTrigger.displayName = "DatePicker.Trigger";

const DatePickerLabel = React.forwardRef<
  React.ElementRef<"label">,
  React.ComponentPropsWithoutRef<"label">
>(({ className, ...props }, ref) => {
  return (
    <label
      ref={ref}
      className={twMerge(
        "mb-inset-x2s block w-fit font-base text-xs/default font-medium text-neutral-darker has-[+_.TextFieldRoot_:disabled]:text-neutral-dark has-[+_.TextFieldRoot_:focus]:text-primary-main",
        className,
      )}
      {...props}
    />
  );
});
DatePickerLabel.displayName = "DatePicker.Label";

const DatePickerDefaultTrigger = React.forwardRef<
  React.ElementRef<"button">,
  React.ComponentPropsWithoutRef<"button">
>(({ children, className, ...props }, ref) => (
  <PopoverPrimitive.Trigger asChild>
    <button
      tabIndex={0}
      ref={ref}
      className={twMerge(
        "flex w-full justify-between rounded-xs border border-neutral-main bg-[transparent] px-4 py-3 text-neutral-darkest placeholder:text-neutral-dark autofill:bg-clip-text disabled:cursor-not-allowed",
        className,
      )}
      {...props}
    >
      {children}
      <Icon
        name="GeralCalendar"
        className="size-6 cursor-pointer fill-current text-neutral-dark"
      />
    </button>
  </PopoverPrimitive.Trigger>
));
DatePickerDefaultTrigger.displayName = "DatePicker.DefaultTrigger";

export const DatePicker = {
  Root: PopoverRoot,
  Trigger: DatePickerTrigger,
  Calendar: PopoverContent,
  Label: DatePickerLabel,
  InputTrigger: DatePickerDefaultTrigger,
};
