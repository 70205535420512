import { DatePicker, Toggle } from "@/components";
import { useGetSearchParams } from "@/features/statements/domain";
import {
  CATEGORIES_DICTIONARY,
  DATE_RANGES,
  formatToInputDate,
  MOVEMENT_DICTIONARY,
  STATEMENTS_FILTER_CATEGORIES,
  STATEMENTS_FILTER_MOVEMENTS,
  TODAY,
} from "@/utils";
import { Button, Checkbox, TextField } from "@hyperlocal/vital2";
import { tv } from "tailwind-variants";
import { useStatementFilter } from "./helper";

const style = tv({
  slots: {
    title: "text-neutral-darkest font-bold text-sm/[30px]",
  },
});

export const Filter = () => {
  const { title } = style();

  const {
    dispatch,
    filterState,
    getSelectedRange,
    resetFilters,
    handleFilter,
    shouldBeAbleToFilter,
    parseInputDate,
    formateDateForUi,
  } = useStatementFilter();

  const { isWowStatements } = useGetSearchParams();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleFilter();
  };

  return (
    <form
      className="flex min-h-[642px] w-full flex-col gap-inset-lg rounded-sm bg-neutral-white p-inset-lg"
      onSubmit={handleSubmit}
    >
      <TextField.Root className="mobile:hidden">
        <TextField.Input
          onChange={({ target }) =>
            dispatch({ type: "SET_SEARCH", payload: target.value })
          }
          value={filterState.search}
          placeholder="Buscar..."
        />
        <TextField.Slot name="SearchNormal" />
      </TextField.Root>
      <div>
        <h5 className={title()}>Filtrar cobranças</h5>
        <div className="mt-4 flex gap-2">
          {DATE_RANGES.map((daysNumber) => (
            <Toggle
              key={daysNumber}
              onPressedChange={() =>
                dispatch({ type: "SET_RANGE", payload: daysNumber })
              }
              pressed={getSelectedRange() === daysNumber}
            >
              {daysNumber} dias
            </Toggle>
          ))}
        </div>
      </div>
      <div>
        <h5 className={title()}>Período personalizado</h5>
        <div className="mt-4 flex gap-4">
          <div className="flex w-full flex-col">
            <DatePicker.Label htmlFor="from-date">De</DatePicker.Label>
            <DatePicker.Root>
              <DatePicker.InputTrigger id="from-date">
                {formateDateForUi(filterState.startDate)}
              </DatePicker.InputTrigger>
              <DatePicker.Calendar
                mode="single"
                selected={parseInputDate(filterState.startDate)}
                onSelect={(date) =>
                  dispatch({
                    type: "SET_FROM_DATE",
                    payload: formatToInputDate(date),
                  })
                }
                disabled={(date) => {
                  if (isWowStatements) return false;
                  return date > TODAY;
                }}
              />
            </DatePicker.Root>
          </div>
          <div className="w-full">
            <DatePicker.Label htmlFor="to-date">Até</DatePicker.Label>
            <DatePicker.Root>
              <DatePicker.InputTrigger id="to-date">
                {formateDateForUi(filterState.endDate)}
              </DatePicker.InputTrigger>
              <DatePicker.Calendar
                mode="single"
                selected={parseInputDate(filterState.endDate)}
                onSelect={(date) =>
                  dispatch({
                    type: "SET_TO_DATE",
                    payload: formatToInputDate(date),
                  })
                }
                disabled={(date) => {
                  if (isWowStatements) return false;
                  return date > TODAY;
                }}
              />
            </DatePicker.Root>
          </div>
        </div>
        <span className="text-status-error-dark">
          {filterState.dateHelperMessage}
        </span>
      </div>

      {!isWowStatements && (
        <div>
          <h5 className={title()}>Tipos de lançamento</h5>
          <div className="mt-4 grid grid-cols-3 gap-4">
            {STATEMENTS_FILTER_MOVEMENTS.map((movement) => (
              <div key={movement} className="flex items-center">
                <Checkbox.Slot
                  id={movement}
                  checked={filterState.movement.includes(movement)}
                  onCheckedChange={() =>
                    dispatch({ type: "SET_MOVEMENT", payload: movement })
                  }
                />
                <Checkbox.Label htmlFor={movement}>
                  {MOVEMENT_DICTIONARY[movement]}
                </Checkbox.Label>
              </div>
            ))}
          </div>
        </div>
      )}

      {!isWowStatements && (
        <div>
          <h5 className={title()}>Categoria</h5>
          <div className="mt-4 grid grid-cols-3 gap-4">
            {STATEMENTS_FILTER_CATEGORIES.map((category) => (
              <div key={category} className="flex items-center">
                <Checkbox.Slot
                  id={category}
                  onCheckedChange={() =>
                    dispatch({ type: "SET_CATEGORIES", payload: category })
                  }
                  checked={
                    !filterState.categories.length ||
                    filterState.categories.includes(category)
                  }
                />
                <Checkbox.Label htmlFor={category}>
                  {CATEGORIES_DICTIONARY[category]}
                </Checkbox.Label>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="mt-auto flex h-auto gap-6">
        <Button.Root
          variant="link"
          type="button"
          fullWidth
          onClick={resetFilters}
        >
          Limpar filtro
        </Button.Root>
        <Button.Root
          onClick={handleFilter}
          disabled={!shouldBeAbleToFilter}
          fullWidth
          type="button"
        >
          Filtrar
        </Button.Root>
      </div>
    </form>
  );
};
