import { useEffect } from "react";
import { ErrorFeedback, LoadingTransaction, ScrollArea } from "@/components";
import { StatementListRequest } from "@/types";
import {
  formatToInputDate,
  getDateTitle,
  handleNormalizeDate,
  isSameDate,
  routes,
  TODAY,
  TODAY_INPUT,
  useAccountStore,
  useNewStatementPermission,
} from "@/utils";
import { formatCurrency } from "@/utils/format";
import { parse, sub } from "date-fns";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { useStatements, useStatementsV2 } from "../../services/hooks";
import { TransactionItem, TransactionItemV2 } from "../transaction-item";

const END_DATE_RANGE = 7;

const endDate = TODAY_INPUT;

const getStartDate = () => {
  const normalizedDate = handleNormalizeDate(TODAY_INPUT);
  const startDate = sub(normalizedDate, { days: END_DATE_RANGE });
  return formatToInputDate(startDate);
};

type TransactionsProps = Pick<
  StatementListRequest<string>,
  "movement" | "type"
>;

const TransactionsV1 = ({ movement, type = [] }: TransactionsProps) => {
  const { ref, inView } = useInView();

  const { currentAccountId } = useAccountStore();

  const { data, fetchNextPage, hasNextPage, isLoading } = useStatements({
    type,
    startDate: getStartDate(),
    endDate,
    movement,
  });

  const isListEmpty = !data || !Object.keys(data?.pages).length;

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (isLoading || !currentAccountId)
    return (
      <div className="p-6">
        <LoadingTransaction />
      </div>
    );

  if (isListEmpty)
    return (
      <div className="flex h-full flex-col items-center p-6">
        <span className="flex h-full w-full items-center justify-center">
          Não há movimentações recentes
        </span>

        <Link
          to={routes.statements}
          className="mt-auto text-xs/md font-medium text-primary-main underline"
        >
          Ver extrato completo
        </Link>
      </div>
    );

  return (
    <div className="flex h-full flex-shrink-0 flex-col items-center overflow-hidden py-inset-lg">
      <ScrollArea className="h-full w-full px-inset-lg pb-inset-lg mobile:!px-inset-md">
        {Object.entries(data.pages).map(([key, items]) => {
          const date = parse(key, "dd/MM/yy", new Date());
          const title = getDateTitle(date);

          const isToday = isSameDate(TODAY, date);
          return (
            <div key={key}>
              <h5 className="font-base text-sm/[30px] font-bold text-neutral-darkest mobile:!text-x3s mobile:!font-medium mobile:!text-neutral-dark">
                {isToday && "Hoje, "}
                {title}
              </h5>
              <ul className="my-4 flex flex-col gap-2">
                {items.map((item) => (
                  <TransactionItem key={item.id} item={item} />
                ))}
              </ul>
            </div>
          );
        })}

        {hasNextPage ? (
          <LoadingTransaction ref={ref} />
        ) : (
          <span className="flex w-full justify-center text-center">
            Não há mais nada por aqui...
            <br /> Para consultar datas anteriores use o filtro.
          </span>
        )}
      </ScrollArea>
      <Link
        to={routes.statements}
        className="text-xs/md font-medium text-primary-main underline"
      >
        Ver extrato completo
      </Link>
    </div>
  );
};

const TransactionsV2 = ({ type = [] }: TransactionsProps) => {
  const { ref, inView } = useInView();

  const { currentAccountId } = useAccountStore();

  const { data, fetchNextPage, hasNextPage, isLoading } = useStatementsV2({
    type,
  });

  const isListEmpty = !data || !Object.keys(data?.pages).length;

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (isLoading || !currentAccountId)
    return (
      <div className="p-6">
        <LoadingTransaction />
      </div>
    );

  if (isListEmpty)
    return (
      <div className="flex h-full flex-col items-center p-6">
        <span className="flex h-full w-full items-center justify-center">
          Não há movimentações recentes
        </span>

        <Link
          to={routes.statements}
          className="mt-auto text-xs/md font-medium text-primary-main underline"
        >
          Ver extrato completo
        </Link>
      </div>
    );

  return (
    <div className="flex h-full flex-shrink-0 flex-col items-center overflow-hidden py-inset-lg">
      <ScrollArea className="h-full w-full px-6 pb-6 mobile:pb-0">
        {Object.entries(data.pages).map(([key, { balance, items }]) => {
          const date = parse(key, "dd/MM/yy", new Date());
          const title = getDateTitle(date);

          const isToday = isSameDate(TODAY, date);
          return (
            <div key={key} className="w-auto">
              <div className="flex items-center justify-between">
                <h5 className="font-base text-sm/[30px] font-bold text-neutral-darkest">
                  {isToday && "Hoje, "}
                  {title}
                </h5>
                {!isToday && !!balance && (
                  <span className="font-base text-xs/tight text-neutral-dark">
                    Saldo do dia: {formatCurrency(balance)}
                  </span>
                )}
              </div>

              <ul className="my-4 flex flex-col gap-2">
                {items.map((item, index) => (
                  <TransactionItemV2 key={index} item={item} />
                ))}
              </ul>
            </div>
          );
        })}

        {hasNextPage ? (
          <LoadingTransaction ref={ref} />
        ) : (
          <span className="flex w-full justify-center text-center">
            Não há mais nada por aqui...
            <br /> Para consultar datas anteriores vá para o extrato completo.
          </span>
        )}
      </ScrollArea>
      <Link
        to={routes.statements}
        className="text-xs/md font-medium text-primary-main underline"
      >
        Ver extrato completo
      </Link>
    </div>
  );
};

export const LastTransactions = (props: TransactionsProps) => {
  const shouldRenderNewStatement = useNewStatementPermission();
  return (
    <ErrorFeedback>
      {shouldRenderNewStatement ? (
        <TransactionsV2 {...props} />
      ) : (
        <TransactionsV1 {...props} />
      )}
    </ErrorFeedback>
  );
};
