import { PayTransferIcon } from "@/assets";
import {
  getStatusColor,
  getTransactionLabel,
} from "@/features/statements/services/utils/get-transaction-status";
import { TransactionItemV2 as Item } from "@/types";
import { formatCurrency } from "@/utils/format";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { Card, Sheet, SheetTriggerProps } from "@hyperlocal/vital2";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { twMerge } from "tailwind-merge";

export const getIcon = (item: Item) => {
  const iconMap: Partial<Record<Item["type"], IconProps["name"]>> = {
    PAYMENT_SLIP: "GeralBarcode",
    PIX: "MoneyPix",
  };

  if (iconMap[item.type])
    return (
      <Icon name={iconMap[item.type]} fill="currentColor" className="size-6" />
    );

  if (item.movement === "CASH_IN")
    return (
      <Icon
        name="GeralMoneyPayReceived"
        fill="currentColor"
        className="size-6"
      />
    );

  return <PayTransferIcon />;
};

const getTitle = (item: Item): string => {
  if (item.feeType) return item.description;

  return getTransactionLabel(item);
};

export const TransactionItemV2 = ({ item }: { item: Item }) => {
  const { value, id, transactionDate } = item;

  const icon = getIcon(item);

  const transactionDateTime = new Date(transactionDate);

  const transactionTime = transactionDateTime.toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const isFee = !!item.feeType;

  return (
    <ReceiptDrawer
      receiptId={id}
      onClick={(e) => {
        if (isFee) return e.preventDefault();
      }}
    >
      <li className="w-full" title={getTitle(item)}>
        <Card
          className={twMerge(
            "statements-list-css-wildcard grid w-full items-center border-neutral-light !px-6 !py-3 mobile:!w-auto mobile:!px-4",
            "grid-cols-[auto_1fr_auto] gap-3",
            isFee && "cursor-not-allowed",
          )}
        >
          <div
            data-testId="icon-container"
            className={twMerge(
              "h-fit w-fit rounded-circle bg-neutral-lighter p-[8px] text-neutral-black mobile:shrink-0 mobile:p-1",
              getStatusColor(item).background,
              getStatusColor(item).text,
            )}
          >
            {icon}
          </div>

          <div className="statement-text-wildcard flex min-w-0 flex-col items-start">
            <span
              className={twMerge(
                "w-full truncate text-left !text-x2s/md text-neutral-darkest mobile:text-x3s",
                getStatusColor(item).status === "error" && "line-through",
              )}
            >
              {getTitle(item)}
            </span>
            {item.participantName && !isFee && (
              <span className="w-full truncate text-left font-base text-xs/normal text-neutral-dark mobile:text-x2s">
                {item.participantName}
              </span>
            )}
          </div>

          <div className="text-right">
            <span className="block text-right text-x2s/md text-neutral-dark mobile:!text-x4s">
              {transactionTime}
            </span>
            <span
              className={twMerge(
                "block whitespace-nowrap text-xs/md text-neutral-darker",
                getStatusColor(item).text,
              )}
            >
              {formatCurrency(value)}
            </span>
          </div>
        </Card>
      </li>
    </ReceiptDrawer>
  );
};

interface ReceiptDrawerProps extends SheetTriggerProps {
  receiptId: string;
}

const ReceiptDrawer = ({ receiptId, ...rest }: ReceiptDrawerProps) => {
  return (
    <Sheet.Root>
      <Sheet.Trigger {...rest} className="flex w-full" />
      <Sheet.Content className="!p-0">
        <Parcel
          config={() => System.import("@hyperlocal/banking-receipt")}
          mountParcel={mountRootParcel}
          receiptId={receiptId}
          receiptOrigin="statements"
        />
      </Sheet.Content>
    </Sheet.Root>
  );
};
