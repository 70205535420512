import {
  ExportRequest,
  StatementListRequest,
  StatementsRequest,
} from "@/types";
import { PaymentScheduleListRequest } from "@/types/commitments";

export const QUERY_KEYS = {
  paymentDefault: ["getPayments"],
  statementsDefault: ["getStatements"],
  getStatements: ({
    endDate,
    startDate,
    movement,
    type,
    accountId,
    isNewStatement,
  }: StatementsRequest & { isNewStatement?: boolean }) => [
    ...QUERY_KEYS.statementsDefault,
    accountId,
    movement,
    startDate,
    endDate,
    type,
    isNewStatement,
  ],
  exportData: ({
    accountId,
    exportType,
    startDate,
    endDate,
  }: ExportRequest) => ["export", accountId, exportType, startDate, endDate],
  getPaymentsSchedule: () => [...QUERY_KEYS.paymentDefault, "list"],
  getInfinitePaymentsSchedule: (params?: PaymentScheduleListRequest) => [
    ...QUERY_KEYS.paymentDefault,
    "infinite-list",
    params?.from,
    params?.to,
  ],
  getStatementsV2: ({
    startDate,
    endDate,
    movement,
    search,
    type,
    accountId,
    isNewStatement,
  }: StatementListRequest<string> & {
    accountId: string;
    isNewStatement?: boolean;
  }) => [
    ...QUERY_KEYS.statementsDefault,
    "V2",
    accountId,
    startDate,
    endDate,
    movement,
    search,
    type,
    isNewStatement,
  ],
};
