import { useState } from "react";
import { useGetSearchParams } from "@/features/statements/domain/search-params";
import { useInfiniteStatements } from "@/features/statements/services/hooks";
import { useExport } from "@/features/statements/services/hooks/use-get-export-data";
import { handleNormalizeDate } from "@/utils";
import { toast } from "@hyperlocal/vital2";
import { parse } from "date-fns";
import { ExportStrategyFactory } from "./export-strategy-factory";
import { ExportDates, FileType } from "./types";

export const handleDownloadError = () => {
  toast({
    title: "Não foi possível exportar o arquivo",
    variant: "error",
  });
};

export const useExportStatements = () => {
  const [fileType, setFileType] = useState<FileType | null>(null);
  const getExportData = useExport();
  const searchParams = useGetSearchParams();

  const { data } = useInfiniteStatements({
    movement: searchParams.movement[0],
    startDate: searchParams.startDate,
    endDate: searchParams.endDate,
    type: searchParams.category,
  });

  const getDates = (): ExportDates => {
    const datesAvailable = Object.keys(data.pages);
    const firstDay = parse(datesAvailable[0], "dd/MM/yy", new Date());
    const lastDay = parse(
      datesAvailable[datesAvailable.length - 1],
      "dd/MM/yy",
      new Date(),
    );
    const startDate = searchParams.startDate
      ? handleNormalizeDate(searchParams.startDate)
      : lastDay;
    const endDate = searchParams.endDate
      ? handleNormalizeDate(searchParams.endDate)
      : firstDay;
    return { startDate, endDate };
  };

  const exportData = async (fileType: FileType) => {
    try {
      const dates = getDates();
      const response = await getExportData.execute({
        endDate: dates.endDate,
        startDate: dates.startDate,
        exportType: fileType !== "ofx" ? "pdf" : "ofx",
      });

      if (!response?.success || !response?.data) {
        handleDownloadError();
        console.error("Export error:", response?.error);
        return;
      }

      const exporter = ExportStrategyFactory.createStrategy(fileType);
      await exporter.export(response.data, dates);

      toast({ title: "Exportação concluída", variant: "success" });
    } catch (error) {
      console.log("Export Error:", error); // TODO: remover apos testes
      handleDownloadError();
    }
  };

  return {
    fileType,
    setFileType,
    exportData,
    getDates,
    getExportData,
  };
};
