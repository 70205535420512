import { DatePicker, Toggle } from "@/components";
import { useGetSearchParams } from "@/features/statements/domain";
import {
  CATEGORIES_DICTIONARY,
  DATE_RANGES,
  formatToInputDate,
  MOVEMENT_DICTIONARY,
  STATEMENTS_FILTER_CATEGORIES,
  STATEMENTS_FILTER_MOVEMENTS,
  TODAY,
} from "@/utils";
import { Button, Checkbox, Sheet, TextField } from "@hyperlocal/vital2";
import { tv } from "tailwind-variants";
import { useStatementFilter } from "./helper";

const style = tv({
  slots: {
    title: "text-neutral-darkest font-bold text-sm/[30px]",
  },
});

export const FilterSheet = () => {
  const { title } = style();

  const {
    dispatch,
    filterState,
    getSelectedRange,
    resetFilters,
    handleFilter,
    shouldBeAbleToFilter,
    formateDateForUi,
    parseInputDate,
  } = useStatementFilter();

  const { category, endDate, startDate, movement, isWowStatements } =
    useGetSearchParams();

  const isParamsFiltered =
    !!startDate || !!endDate || !!movement || !!category.length;

  return (
    <Sheet.Root>
      <Sheet.Trigger asChild>
        <Button.Root
          variant={isParamsFiltered ? "primary" : "secondary"}
          className="mb-4 h-min min-h-0 w-fit !px-2 !py-2 text-[14px] !leading-3 desktop:!hidden"
          style={{ height: "auto" }}
        >
          <Button.Slot name="FilterFilter" className="size-4" />
          Filtrar extrato
          {isParamsFiltered && (
            <Button.Slot name="GeralClose" className="size-4" />
          )}
        </Button.Root>
      </Sheet.Trigger>
      <Sheet.Content className="gap-0 !px-0 !py-0">
        <Sheet.Header className="mb-0 w-full p-inset-md">
          <Sheet.Title className="text-sm/default font-bold text-neutral-darkest">
            Filtrar extrato
          </Sheet.Title>
        </Sheet.Header>
        <div className="h-[1px] w-full bg-neutral-lighter shadow-lg" />
        <form className="flex h-full flex-col gap-inset-md rounded-sm bg-neutral-white px-inset-md py-inset-lg">
          <TextField.Root className="mb-4 desktop:!hidden">
            <TextField.Input
              onChange={({ target }) =>
                dispatch({ payload: target.value, type: "SET_SEARCH" })
              }
              value={filterState.search}
              placeholder="Buscar..."
            />
            <TextField.Slot name="SearchNormal" />
          </TextField.Root>
          <div>
            <div className="flex gap-2">
              {DATE_RANGES.map((daysNumber) => (
                <Toggle
                  key={daysNumber}
                  onPressedChange={() =>
                    dispatch({ type: "SET_RANGE", payload: daysNumber })
                  }
                  pressed={getSelectedRange() === daysNumber}
                >
                  {daysNumber} dias
                </Toggle>
              ))}
            </div>
          </div>
          <div>
            <h5 className={title()}>Período personalizado</h5>
            <div className="mt-4 flex gap-4">
              <div className="flex w-full flex-col">
                <DatePicker.Label htmlFor="sheet-from-date">
                  De
                </DatePicker.Label>
                <DatePicker.Root>
                  <DatePicker.InputTrigger id="sheet-from-date">
                    {formateDateForUi(filterState.startDate)}
                  </DatePicker.InputTrigger>
                  <DatePicker.Calendar
                    mode="single"
                    selected={parseInputDate(filterState.startDate)}
                    onSelect={(date) =>
                      dispatch({
                        type: "SET_FROM_DATE",
                        payload: formatToInputDate(date),
                      })
                    }
                    disabled={(date) => {
                      if (isWowStatements) return false;
                      return date > TODAY;
                    }}
                  />
                </DatePicker.Root>
              </div>
              <div className="w-full">
                <DatePicker.Label htmlFor="to-date">Até</DatePicker.Label>
                <DatePicker.Root>
                  <DatePicker.InputTrigger id="to-date">
                    {formateDateForUi(filterState.endDate)}
                  </DatePicker.InputTrigger>
                  <DatePicker.Calendar
                    mode="single"
                    selected={parseInputDate(filterState.endDate)}
                    onSelect={(date) =>
                      dispatch({
                        type: "SET_TO_DATE",
                        payload: formatToInputDate(date),
                      })
                    }
                    disabled={(date) => {
                      if (isWowStatements) return false;
                      return date > TODAY;
                    }}
                  />
                </DatePicker.Root>
              </div>
            </div>
            <span className="text-status-error-dark">
              {filterState.dateHelperMessage}
            </span>
          </div>
          {!isWowStatements && (
            <div>
              <h5 className={title()}>Tipos de lançamento</h5>
              <div className="mt-4 grid grid-cols-2 gap-4">
                {STATEMENTS_FILTER_MOVEMENTS.map((movement) => (
                  <div key={movement} className="flex items-center">
                    <Checkbox.Slot
                      id={`sheet-${movement}`}
                      checked={filterState.movement.includes(movement)}
                      onCheckedChange={() =>
                        dispatch({ type: "SET_MOVEMENT", payload: movement })
                      }
                    />
                    <Checkbox.Label htmlFor={`sheet-${movement}`}>
                      {MOVEMENT_DICTIONARY[movement]}
                    </Checkbox.Label>
                  </div>
                ))}
              </div>
            </div>
          )}
          {!isWowStatements && (
            <div>
              <h5 className={title()}>Categoria</h5>
              <div className="mt-4 grid grid-cols-2 gap-4">
                {STATEMENTS_FILTER_CATEGORIES.map((category) => (
                  <div key={category} className="flex items-center">
                    <Checkbox.Slot
                      id={`sheet-${category}`}
                      onCheckedChange={() =>
                        dispatch({ type: "SET_CATEGORIES", payload: category })
                      }
                      checked={
                        !filterState.categories.length ||
                        filterState.categories.includes(category)
                      }
                    />
                    <Checkbox.Label htmlFor={`sheet-${category}`}>
                      {CATEGORIES_DICTIONARY[category]}
                    </Checkbox.Label>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="mt-auto w-full">
            <Sheet.Close asChild>
              <Button.Root
                onClick={handleFilter}
                disabled={!shouldBeAbleToFilter}
                fullWidth
                type="button"
              >
                Filtrar
              </Button.Root>
            </Sheet.Close>
            <Button.Root
              fullWidth
              variant="link"
              type="button"
              onClick={resetFilters}
            >
              Limpar filtro
            </Button.Root>
          </div>
        </form>
      </Sheet.Content>
    </Sheet.Root>
  );
};
