import { useGetSearchParams } from "@/features/statements/domain";
import {
  Balances,
  StatementListRequest,
  StatementListResponse,
  StatementListResponseV2,
  TransactionItem,
  TransactionItemV2,
  TransactionType,
} from "@/types";
import { QUERY_KEYS } from "@/utils/react-query-keys";
import { transformResponseV2 } from "@/utils/transform";
import { API, useAccountStore } from "@/utils/utility";
import {
  InfiniteData,
  useInfiniteQuery,
  UseQueryOptions,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { handleStatementsResponse } from "./use-get-statements.utils";

interface UseStatements
  extends Omit<StatementListRequest<number>, "accountId"> {
  config?: UseQueryOptions<StatementListResponse>;
}

const THIRTY_SECONDS = 30000;

const getStatementParams = ({
  startDate,
  movement,
  endDate,
  type,
  _page,
  limit,
}: StatementListRequest<number>) => {
  const params = new URLSearchParams();

  if (type.length) {
    params.set("type", type.join("-"));
  }

  if (movement) {
    params.set("movement", movement);
  }

  if (startDate && endDate) {
    params.set("startDate", startDate);
    params.set("endDate", endDate);
  }

  if (limit) params.set("limitPage", limit.toString());

  if (_page) params.set("page", _page.toString());

  return params;
};

export const getBankStatement = async (props: StatementListRequest<number>) => {
  const params = getStatementParams(props);

  const { data } = await API.statements.get<StatementListResponse>(`/Extract`, {
    params,
  });

  return data;
};

export const useStatementsList = ({
  movement,
  type,
  config,
  endDate,
  startDate,
  limit,
}: UseStatements) => {
  const { search } = useGetSearchParams();

  const { currentAccountId } = useAccountStore();

  return useSuspenseQuery({
    ...config,
    queryKey: QUERY_KEYS.getStatements({
      accountId: currentAccountId,
      startDate,
      endDate,
      movement,
      type,
      limit,
    }),
    queryFn: () =>
      getBankStatement({
        startDate,
        movement,
        endDate,
        type,
        limit,
      }),
    select: (data) => {
      const transformedResponse = handleStatementsResponse(data.Items, search);
      return transformedResponse;
    },
  });
};

export const useInfiniteStatements = ({
  startDate,
  movement,
  endDate,
  type,
}: UseStatements) => {
  const { search } = useGetSearchParams();

  const shouldUpdateDateQueryKey = !!endDate && !!startDate;

  const { currentAccountId } = useAccountStore();

  return useSuspenseInfiniteQuery({
    queryKey: QUERY_KEYS.getStatements({
      accountId: currentAccountId || "",
      movement,
      type,
      ...(shouldUpdateDateQueryKey && {
        endDate,
        startDate,
      }),
    }),
    initialPageParam: 0,
    select: (data: InfiniteData<StatementListResponse, number>) => {
      const result = data.pages.reduce<TransactionItem[]>(
        (prev, cur) => [...prev, ...cur.Items],
        [],
      );

      const transformedResponse = handleStatementsResponse(result, search);

      return {
        pages: transformedResponse,
        pageParams: data.pageParams,
      };
    },
    queryFn: ({ pageParam }) =>
      getBankStatement({
        startDate,
        movement,
        endDate,
        type,
        _page: pageParam,
      }),

    getNextPageParam: (page, _, lastPageParam) => {
      return page.Pagination.Next ? lastPageParam + 1 : undefined;
    },
  });
};

export const useStatements = ({
  startDate,
  movement,
  endDate,
  type,
}: UseStatements) => {
  const { search } = useGetSearchParams();

  const shouldUpdateDateQueryKey = !!endDate && !!startDate;

  const { currentAccountId } = useAccountStore();

  return useInfiniteQuery({
    queryKey: QUERY_KEYS.getStatements({
      accountId: currentAccountId || "",
      movement,
      type,
      ...(shouldUpdateDateQueryKey && {
        endDate,
        startDate,
      }),
    }),
    throwOnError: true,
    enabled: !!currentAccountId,
    initialPageParam: 0,
    select: (data: InfiniteData<StatementListResponse, number>) => {
      const result = data.pages.reduce<TransactionItem[]>(
        (prev, cur) => [...prev, ...cur.Items],
        [],
      );

      const transformedResponse = handleStatementsResponse(result, search);
      return {
        pages: transformedResponse,
        pageParams: data.pageParams,
      };
    },
    queryFn: ({ pageParam }) =>
      getBankStatement({
        startDate,
        movement,
        endDate,
        type,
        _page: pageParam,
      }),

    getNextPageParam: (page, _, lastPageParam) => {
      return page.Pagination.Next ? lastPageParam + 1 : undefined;
    },
  });
};

const getStatementParamsV2 = ({
  startDate,
  movement,
  endDate,
  type,
  _page,
  search,
}: StatementListRequest<string>) => {
  const params = new URLSearchParams();

  if (type && type.length) {
    const typeMap: Partial<Record<TransactionType, string>> = {
      P2P: "P2P",
      Pix: "PIX",
      TED: "TED",
      Payment: "PAYMENT_SLIP",
    };

    params.set(
      "transactionTypes",
      type.map((transactionType) => typeMap[transactionType]).join(","),
    );
  }

  if (movement) {
    const movementMap = {
      OUT: "CASH_OUT",
      IN: "CASH_IN",
    };
    params.set("operation", movementMap[movement]);
  }

  if (startDate && endDate) {
    params.set("startDate", startDate);
    params.set("endDate", endDate);
  }

  params.set("limitPage", "10");

  if (search) params.set("description", search);

  if (_page) params.set("lastEvaluatedKey", _page);

  return params;
};

export const getBankStatementV2 = async (
  props: StatementListRequest<string>,
) => {
  const params = getStatementParamsV2(props);

  const { data } = await API.statements.get<StatementListResponseV2>(
    `/v2/Extract`,
    {
      params,
    },
  );

  return data;
};

export const useInfiniteStatementsV2 = () => {
  const { currentAccountId } = useAccountStore();
  const { startDate, endDate, movement, category, search } =
    useGetSearchParams();

  return useSuspenseInfiniteQuery({
    queryKey: QUERY_KEYS.getStatementsV2({
      accountId: currentAccountId,
      startDate,
      endDate,
      search,
      movement: movement[0],
      type: category,
    }),
    refetchInterval: THIRTY_SECONDS,
    initialPageParam: "",
    queryFn: ({ pageParam }) =>
      getBankStatementV2({
        startDate,
        movement: movement[0],
        type: category,
        endDate,
        _page: pageParam,
        search,
      }),
    select: (data: InfiniteData<StatementListResponseV2, string>) => {
      const { balances, items } = data.pages.reduce<{
        balances: Balances[];
        items: TransactionItemV2[];
      }>(
        (prev, cur) => {
          prev.balances = [...(prev.balances || []), ...(cur.balances || [])];

          prev.items = [...prev.items, ...cur.items];

          return prev;
        },
        {
          balances: [],
          items: [],
        },
      );

      const transformedResponse = transformResponseV2({ balances, items });
      return {
        pages: transformedResponse,
        pageParams: data.pageParams,
      };
    },
    getNextPageParam: (page) => {
      return page.pagination.lastEvaluatedKey || undefined;
    },
  });
};

export const useStatementsV2 = ({
  type,
}: {
  type: StatementListRequest<string>["type"];
}) => {
  const { currentAccountId } = useAccountStore();
  const { startDate, endDate } = useGetSearchParams();

  return useInfiniteQuery({
    queryKey: QUERY_KEYS.getStatementsV2({
      accountId: currentAccountId || "",
      startDate,
      endDate,
      type,
    }),
    initialPageParam: "",
    queryFn: ({ pageParam }) =>
      getBankStatementV2({
        _page: pageParam,
        startDate,
        endDate,
        type,
      }),
    select: (data: InfiniteData<StatementListResponseV2, string>) => {
      const { balances, items } = data.pages.reduce<{
        balances: Balances[];
        items: TransactionItemV2[];
      }>(
        (prev, cur) => {
          prev.balances = [...(prev.balances || []), ...(cur.balances || [])];

          prev.items = [...prev.items, ...cur.items];

          return prev;
        },
        {
          balances: [],
          items: [],
        },
      );

      const transformedResponse = transformResponseV2({ balances, items });
      return {
        pages: transformedResponse,
        pageParams: data.pageParams,
      };
    },
    getNextPageParam: (page) => {
      return page.pagination.lastEvaluatedKey || undefined;
    },
    throwOnError: true,
  });
};
