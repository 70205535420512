/* eslint-disable @typescript-eslint/ban-ts-comment */
import "./index.css";
import { queryClient } from "@/lib";
import { ParcelProps } from "@/types";
// @ts-ignore
import { Hooks } from "@hyperlocal/banking-utility";
import { Provider } from "@hyperlocal/vital";
import { Toaster } from "@hyperlocal/vital2";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes";
import { getVertical } from "./utils";

const App = (props: ParcelProps) => {
  const theme = getVertical();
  const isMobile = Hooks.useMediaQuery("mobile");

  return (
    <Provider theme={theme}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === "development" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
          <Routes {...props} />
          <Toaster position={isMobile ? "bottom-center" : "top-right"} />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
