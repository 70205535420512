import { ElementRef, useRef, useState } from "react";
import {
  formatToInputDate,
  handleNormalizeDate,
  isValidDate,
  MIN_START_DATE,
  TODAY_INPUT,
  useMediaQuery,
} from "@/utils";
import { Button, Dialog, Sheet, TextField } from "@hyperlocal/vital2";
import { addDays, subDays } from "date-fns";

const EXPORT_MAX_RANGE = 90; // DIAS

const handleDateInput = (dateValue: string) => {
  const date = handleNormalizeDate(dateValue);

  if (!isValidDate(date)) return "";

  return dateValue;
};

const ExportContent = () => {
  const [dates, setDates] = useState<{ startDate: string; endDate: string }>({
    endDate: "",
    startDate: "",
  });

  const getMaxDateRanges = () => {
    const startDateMaxRange = dates.endDate
      ? formatToInputDate(subDays(new Date(dates.endDate), EXPORT_MAX_RANGE))
      : "";

    const endDateMaxRange = dates.startDate
      ? formatToInputDate(addDays(new Date(dates.startDate), EXPORT_MAX_RANGE))
      : TODAY_INPUT;

    return {
      startDateMaxRange,
      endDateMaxRange,
    };
  };

  const clearDate = (date: keyof typeof dates) => {
    setDates((prev) => ({ ...prev, [date]: "" }));
  };

  return (
    <div className="flex flex-col gap-3">
      <span>
        Selecione o período para exportação (máximo de 90 dias). <br />O arquivo
        será enviado para seu e-mail.
      </span>
      <div className="flex gap-4 mobile:flex-col">
        <div className="w-full">
          <TextField.Label htmlFor="startDate">De</TextField.Label>
          <TextField.Root>
            <TextField.Input
              id="startDate"
              type="date"
              value={dates.startDate}
              min={getMaxDateRanges().startDateMaxRange}
              max={dates.endDate || TODAY_INPUT}
              onChange={({ target }) =>
                setDates((prev) => ({
                  ...prev,
                  startDate: handleDateInput(target.value),
                }))
              }
            />
            {!dates.startDate ? (
              <TextField.Slot name="GeralCalendar" className="cursor-pointer" />
            ) : (
              <TextField.Slot
                name="GeralClose"
                onClick={() => clearDate("startDate")}
              />
            )}
          </TextField.Root>
        </div>
        <div className="w-full">
          <TextField.Label htmlFor="endDate">Até</TextField.Label>
          <TextField.Root>
            <TextField.Input
              id="endDate"
              type="date"
              value={dates.endDate}
              min={dates.startDate || MIN_START_DATE}
              max={getMaxDateRanges().endDateMaxRange}
              onChange={({ target }) =>
                setDates((prev) => ({
                  ...prev,
                  endDate: handleDateInput(target.value),
                }))
              }
            />
            {!dates.endDate ? (
              <TextField.Slot name="GeralCalendar" className="cursor-pointer" />
            ) : (
              <TextField.Slot
                name="GeralClose"
                onClick={() => clearDate("endDate")}
              />
            )}
          </TextField.Root>
        </div>
      </div>
      <Button.Root fullWidth>Enviar</Button.Root>
    </div>
  );
};

export const ExportStatementsV2 = () => {
  const isMobile = useMediaQuery("mobile");
  const dialogRef = useRef<ElementRef<typeof Dialog.Close>>(null);

  return (
    <>
      {!isMobile && (
        <div>
          <Dialog.Root>
            <Dialog.Trigger asChild>
              <Button.Root
                className="button-export-wildcard mb-4 flex !h-8 w-fit items-center space-x-2 border border-primary-main px-3 py-2 text-xs leading-none text-primary-main"
                variant="secondary"
              >
                <Button.Slot name="GeralShareExport" className="size-[24px]" />
                Exportar
              </Button.Root>
            </Dialog.Trigger>
            <Dialog.Content>
              <Dialog.Header>
                <Dialog.Title>Exportar</Dialog.Title>
                <Dialog.Close ref={dialogRef}></Dialog.Close>
              </Dialog.Header>
              <ExportContent />
            </Dialog.Content>
          </Dialog.Root>
        </div>
      )}
      {isMobile && (
        <div>
          <Sheet.Root>
            <Sheet.Trigger asChild>
              <Button.Root
                className="!h-fit shrink-0 !p-0 !px-2 !py-2 text-xs leading-none"
                variant="link"
              >
                <Button.Slot name="GeralShareExport" className="size-6" />
                Exportar
              </Button.Root>
            </Sheet.Trigger>
            <Sheet.Content side="bottom">
              <Sheet.Header>
                <Sheet.Title>Exportar</Sheet.Title>
                <Dialog.Close ref={dialogRef}></Dialog.Close>
              </Sheet.Header>
              <ExportContent />
            </Sheet.Content>
          </Sheet.Root>
        </div>
      )}
    </>
  );
};
