import { Suspense } from "react";
import { useNewStatementPermission } from "@/utils";
import { ErrorBoundary } from "react-error-boundary";
import { ExportStatementsV1 } from "./export-statementsv1";
import { ExportStatementsV2 } from "./export-statementsv2";

export const ExportStatementsAction = () => {
  const shouldRenderNewStatement = useNewStatementPermission();

  return (
    <ErrorBoundary FallbackComponent={() => <div />}>
      <Suspense>
        {shouldRenderNewStatement ? (
          <ExportStatementsV2 />
        ) : (
          <ExportStatementsV1 />
        )}
      </Suspense>
    </ErrorBoundary>
  );
};
