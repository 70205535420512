import { PayTransferIcon } from "@/assets";
import {
  TransactionItemTransformed as Item,
  TransactionMovement,
  TransactionType,
} from "@/types";
import { formatCurrency } from "@/utils/format";
import Icon, { IconProps } from "@hyperlocal/vital-icons";
import { Card, Sheet, SheetTriggerProps } from "@hyperlocal/vital2";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { twMerge } from "tailwind-merge";
import { WowTransactionReceipt } from "../wow-transaction-receipt";

export interface TransactionItemProps {
  item: Item;
  openReceipt?: boolean;
}

export const getIcon = (item: Item) => {
  const iconMap: Partial<Record<TransactionType & "PIX", IconProps["name"]>> = {
    Payment: "GeralBarcode",
    PIX: "MoneyPix",
    cartao: "MoneyCard",
  };

  if (iconMap[item.rawType])
    return (
      <Icon
        name={iconMap[item.rawType]}
        fill="currentColor"
        className="size-6"
      />
    );

  if (item.movement === "Entrada")
    return (
      <Icon
        name="GeralMoneyPayReceived"
        fill="currentColor"
        className="size-6"
      />
    );

  return <PayTransferIcon />;
};

const isTedFee = (item: Item) => {
  const { rawType, movement, participant, description } = item;
  const isTedTransaction = rawType === "TED";
  const isTariffRelated = description.toLowerCase().includes("tarifa");
  const isOutgoingMovement = movement === "Saída";
  const isTariffRequestParticipant = participant === "TARIFF_REQUEST";

  return (
    isTedTransaction &&
    isTariffRelated &&
    isOutgoingMovement &&
    isTariffRequestParticipant
  );
};

const getMovementDescription = (item: Item) => {
  const { rawType, movement, description } = item;

  const isInMovement = movement === "Entrada";
  const isPaymentReversal =
    isInMovement &&
    rawType === "Payment" &&
    description === "Pgto Contas nao Processado";

  if (isTedFee(item)) {
    return "Tarifa TED";
  }

  if (isPaymentReversal) return "Estorno Recebido";

  return mappedRawType[rawType][movement];
};

type MappedRawType = Record<
  string,
  {
    Saída: string;
    Entrada: string;
  }
>;

const mappedRawType: MappedRawType = {
  Payment: {
    Saída: "Pagamento Efetuado",
    Entrada: "Pagamento Recebido",
  },
  P2P: {
    Saída: "Transferência Enviada",
    Entrada: "Transferência Recebida",
  },
  TED: {
    Saída: "Transferência Enviada",
    Entrada: "Transferência Recebida",
  },
  PIX: {
    Saída: "Pix Transferência Enviada",
    Entrada: "Pix Transferência Recebida",
  },
  Mobile: {
    Saída: "Recarga de celular",
    Entrada: "Recarga de celular",
  },
  Voucher: {
    Saída: "Pagamento de voucher",
    Entrada: "Recebimento de voucher",
  },
};

export const TransactionItem = ({
  item,
  openReceipt = true,
}: TransactionItemProps) => {
  const { createdAt, value, movement, rawType } = item;

  const icon = getIcon(item);

  const isMovementIn = movement === "Entrada";
  const transactionValue = isMovementIn ? value : value * -1;

  const date = new Date(createdAt);

  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  const transactionTime = `${hours}:${minutes}`;

  const movementDescription = getMovementDescription(item);

  if (openReceipt) {
    return (
      <ReceiptDrawer
        receiptId={item.id}
        receiptType={rawType}
        movement={item.movement === "Entrada" ? "IN" : "OUT"}
        onClick={(e) => {
          if (isTedFee(item)) return e.preventDefault();
        }}
      >
        <li className="w-full">
          <Card
            className={twMerge(
              "statements-list-css-wildcard flex w-full items-center border-neutral-light !px-6 !py-3 mobile:!w-auto mobile:!px-4",
              isTedFee(item) && "cursor-not-allowed",
            )}
          >
            <div
              data-testId="icon-container"
              className={twMerge(
                "h-fit w-fit rounded-circle bg-neutral-lighter p-[8px] text-neutral-black",
                isMovementIn &&
                  "bg-status-success-light text-status-success-dark",
              )}
            >
              {icon}
            </div>
            <div className="statement-text-wildcard ml-3 flex w-full flex-col items-start">
              <span className="text-left text-x2s/md text-neutral-darkest">
                {movementDescription}
              </span>
            </div>
            <div className="ml-auto flex flex-col text-right">
              <span className="text-right text-x2s/md text-neutral-dark mobile:!text-x4s">
                {transactionTime}
              </span>
              <span
                className={twMerge(
                  "w-max text-xs/md",
                  isMovementIn
                    ? "text-status-success-dark"
                    : "text-neutral-darker",
                )}
              >
                {formatCurrency(transactionValue)}
              </span>
            </div>
          </Card>
        </li>
      </ReceiptDrawer>
    );
  }

  return (
    <WowTransactionReceipt item={item}>
      <li className="w-full">
        <Card
          className={twMerge(
            "statements-list-css-wildcard flex w-full cursor-pointer items-center border-neutral-light !px-6 !py-3",
            isTedFee(item) && "cursor-not-allowed",
          )}
        >
          <div
            data-testId="icon-container"
            className={twMerge(
              "h-fit w-fit rounded-circle bg-neutral-lighter p-[8px] text-neutral-black",
              isMovementIn &&
                "bg-status-success-light text-status-success-dark",
            )}
          >
            {icon}
          </div>

          <div className="statement-text-wildcard ml-3 flex flex-col items-start">
            <span className="text-left text-x2s/md text-neutral-darkest">
              {movementDescription}
            </span>
          </div>

          <div className="ml-auto flex flex-col text-right">
            <span className="text-right text-x2s/md text-neutral-dark mobile:!text-x4s">
              {transactionTime}
            </span>
            <span
              className={twMerge(
                "w-max text-xs/md",
                isMovementIn
                  ? "text-status-success-dark"
                  : "text-neutral-darker",
              )}
            >
              {formatCurrency(transactionValue)}
            </span>
          </div>
        </Card>
      </li>
    </WowTransactionReceipt>
  );
};

interface ReceiptDrawerProps extends SheetTriggerProps {
  receiptId: string;
  receiptType: string;
  movement: TransactionMovement;
}

const ReceiptDrawer = ({
  receiptId,
  receiptType,
  movement,
  ...rest
}: ReceiptDrawerProps) => {
  return (
    <Sheet.Root>
      <Sheet.Trigger {...rest} className="flex w-full" />
      <Sheet.Content className="!p-0">
        <Parcel
          config={() => System.import("@hyperlocal/banking-receipt")}
          mountParcel={mountRootParcel}
          receiptId={receiptId}
          receiptType={receiptType}
          movement={movement}
          receiptOrigin="statements"
        />
      </Sheet.Content>
    </Sheet.Root>
  );
};
