import { TODAY } from "@/utils/constants";
import { format } from "date-fns";

export const isValidDate = (date: Date) => {
  return date instanceof Date && Number.isFinite(date.getTime());
};

export const formatDateToApi = (date: Date) => {
  if (isValidDate(date) && date) {
    return date.toISOString().split("T")[0];
  }
  return undefined;
};

export const formatBrDate = (date: Date) => {
  if (!isValidDate(date)) return "";
  return new Intl.DateTimeFormat("pt-BR").format(date);
};

export const handleNormalizeDate = (date: Date | string) => {
  const parsedDate = new Date(date);
  parsedDate.setHours(0, 0, 0, 0);
  parsedDate.setDate(parsedDate.getDate() + 1);
  return parsedDate;
};

export const formatToInputDate = (date: Date) => {
  if (!isValidDate(date)) return "";
  return format(date, "yyyy-MM-dd");
};

// 31/08/2023 -> 08/23
export const getGroupedMonth = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    month: "2-digit",
    year: "2-digit",
  });
};
// 31/08/2023 -> 31/08
export const getGroupedDay = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
  });
};

export const getGroupedDate = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
};

// 01/04/2024 -> 01 de abril
export const getDateTitle = (date: Date) => {
  return new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "long",
  });
};

export const getTime = (date: Date) => {
  return new Date(date).toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

export const isSameDate = (dateA: Date, dateB: Date) => {
  dateA.setHours(0, 0, 0, 0);
  dateB.setHours(0, 0, 0, 0);

  return dateA.getTime() === dateB.getTime();
};

const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const getCurrentMonth = () =>
  capitalizeFirstLetter(
    TODAY.toLocaleDateString("pt-BR", {
      month: "long",
    }),
  );

export const getDay = (date: Date) =>
  new Date(date).toLocaleDateString("pt-BR", {
    day: "2-digit",
  });

export const getShortMonth = (date: Date) =>
  new Date(date).toLocaleDateString("pt-BR", {
    month: "short",
  });
