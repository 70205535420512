import { useEffect, useState } from "react";
import { appVersionQueryFactory, STATEMENTS_CDN } from "@/utils";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export function useCheckAppVersion() {
  const [route, setRoute] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setRoute(window.location.pathname);
    };
    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const queryOptions = appVersionQueryFactory.check({
    url: STATEMENTS_CDN,
    route,
    storageKey: "STATEMENTS_VERSION",
  }) as any;

  return useQuery({
    ...queryOptions,
  });
}
