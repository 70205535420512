import { TransactionItemV2 } from "@/types";
import { getTransactionStatus } from "@/utils";

export const getTransactionLabel = (transaction: TransactionItemV2) => {
  const status = getTransactionStatus({
    category: transaction.type,
    status: transaction.status,
    type: transaction.movement,
  }).toLocaleLowerCase();

  const labelMap: Record<TransactionItemV2["type"], string> = {
    P2P: `Transferência Hyperlocal ${status}`,
    PIX: `Transferência Pix ${status}`,
    TED: `Transferência TED ${status}`,
    PAYMENT_SLIP: `Pagamento ${status}`,
  };

  return labelMap[transaction.type];
};

export const getStatusColor = (
  item: TransactionItemV2,
): {
  background: string;
  text: string;
  status: "cashin" | "error" | "";
} => {
  const isCashIn = item.movement === "CASH_IN";

  if (isCashIn)
    return {
      background: "bg-status-success-light",
      text: "text-status-success-dark",
      status: "cashin",
    };

  const errorStatus: Array<TransactionItemV2["status"]> = [
    "ERROR",
    "FAILED",
    "REJECTED",
    "CANCELLED",
    "DENIED",
    "EXPIRED",
  ];

  if (errorStatus.includes(item.status))
    return {
      background: "bg-status-error-light",
      text: "text-status-error-dark",
      status: "error",
    };

  // default
  return {
    background: "",
    text: "",
    status: "",
  };
};
