import AvecLogo from "@/assets/Avec.png";
import CrossXLogo from "@/assets/CrossX.png";
import HyperlocalLogo from "@/assets/Hyperlocal.png";
import { TransactionItem, TransactionItemTransformed } from "@/types";
import {
  Account,
  autoTable,
  ExcelJs,
  getVertical,
  JSpdf,
  NativeEventSender,
  useAccountStore,
  Vertical,
} from "@/utils";
import {
  CSVExportData,
  PDFExportData,
  transformCSVStatementsResponse,
  transformPDFStatementsResponse,
  transformStatementsResponse,
} from "@/utils/transform";
import { formatCNPJ, formatCPF } from "@brazilian-utils/brazilian-utils";
import moment from "moment";
import { ExportDates, ExportStrategy } from "./types";

type OfxParams = {
  startDate: string;
  endDate: string;
  data: TransactionItemTransformed[];
};
export class OFXExporter implements ExportStrategy {
  private account: Account;
  constructor(private readonly eventSender: NativeEventSender<any>) {
    this.account = useAccountStore.getState().account;
  }

  private generateBlob({ data, endDate, startDate }: OfxParams): Blob {
    const dateTimeStart = moment(moment(startDate).format("YYYY-MM-DD")).format(
      "YYYYMMDDHHmmss",
    );
    const dateTimeEnd = moment(moment(endDate).format("YYYY-MM-DD")).format(
      "YYYYMMDD235959",
    );
    const dateTimeNow = moment().format("YYYYMMDDHHmmss");
    // Cabeçalho do arquivo OFX
    const header = `OFXHEADER:100
  DATA:OFXSGML
  VERSION:102
  SECURITY:NONE
  ENCODING:USASCII
  CHARSET:1252
  COMPRESSION:NONE
  OLDFILEUID:NONE
  NEWFILEUID:NONE
  
  `;
    // Corpo do documento OFX
    const body = `
  <OFX>
    <SIGNONMSGSRSV1>
      <SONRS>
        <STATUS>
          <CODE>0</CODE>
          <SEVERITY>INFO</SEVERITY>
          <MESSAGE>Success</MESSAGE>
        </STATUS>
        <DTSERVER>${dateTimeNow}</DTSERVER>
        <LANGUAGE>ENG</LANGUAGE>
      </SONRS>
    </SIGNONMSGSRSV1>
    <BANKMSGSRSV1>
      <STMTTRNRS>
        <TRNUID>0</TRNUID>
        <STATUS>
          <CODE>0</CODE>
          <SEVERITY>INFO</SEVERITY>
          <MESSAGE>Success</MESSAGE>
        </STATUS>
        <STMTRS>
          <CURDEF>BRL</CURDEF>
          <BANKACCTFROM>
            <BANKID>301</BANKID>
            <ACCTID>${this.account.accountNumber}</ACCTID>
            <ACCTTYPE>CHECKING</ACCTTYPE>
          </BANKACCTFROM>
          <BANKTRANLIST>
            <DTSTART>${dateTimeStart}</DTSTART>
            <DTEND>${dateTimeEnd}</DTEND>`;
    const transactions = data
      .map(
        (item) => `
            <STMTTRN>
              <TRNTYPE>${item.movement === "Entrada" ? "CREDIT" : "DEBIT"}</TRNTYPE>
              <DTPOSTED>${moment(new Date(item.createdAt)).format("YYYYMMDDHHmmss")}</DTPOSTED>
              <TRNAMT>${item.value}</TRNAMT>
              <FITID>${item.id}</FITID>
              ${item.participant && `<NAME>${item.participant}</NAME>`}
              ${item.description && `<MEMO>${item.description}</MEMO>`}
            </STMTTRN>`,
      )
      .join("");
    const footer = `
          </BANKTRANLIST>
          <LEDGERBAL>
            <BALAMT>0</BALAMT>
            <DTASOF>${dateTimeNow}</DTASOF>
          </LEDGERBAL>
          <AVAILBAL>
            <BALAMT>0</BALAMT>
            <DTASOF>${dateTimeNow}</DTASOF>
          </AVAILBAL>
        </STMTRS>
      </STMTTRNRS>
    </BANKMSGSRSV1>
  </OFX>`;
    const content = header + body + transactions + footer;
    return new Blob([content], { type: "application/x-ofx" });
  }

  async export(data: TransactionItem[], dates: ExportDates): Promise<void> {
    try {
      const transformedData = transformStatementsResponse(data);
      const blob = this.generateBlob({
        data: transformedData,
        endDate: dates.endDate.toLocaleDateString(),
        startDate: dates.startDate.toLocaleDateString(),
      });
      if (this.eventSender.getIsNative()) {
        const buffer = await blob.arrayBuffer();
        const base64 = btoa(
          new Uint8Array(buffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            "",
          ),
        );
        this.eventSender.sendMessage("FILE_DOWNLOAD", {
          base64,
          filename: "extrato.ofx",
        });
      } else {
        await this.downloadFile(blob, "ofx");
      }
    } catch (error) {
      throw new Error(`Error exporting OFX: ${error.message}`);
    }
  }

  private async downloadFile(blob: Blob, extension: string): Promise<void> {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `extrato.${extension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
}
export class PDFExporter implements ExportStrategy {
  private pdf: JSpdf;
  private account: Account;
  constructor(private readonly eventSender: NativeEventSender<any>) {
    this.pdf = new JSpdf("p", "mm", "a4");
    this.account = useAccountStore.getState().account;
  }

  private addLogo(vertical: Vertical, margin: number, y: number) {
    const logo: Partial<Record<Vertical, string>> = {
      avec: AvecLogo,
      crossX: CrossXLogo,
      hyperlocal: HyperlocalLogo,
    };
    const imgLogo = new Image();
    imgLogo.src = logo[vertical] || logo.hyperlocal;
    this.pdf.addImage(imgLogo, "PNG", margin, y, 50, 25);
    y += 50;
    return y;
  }

  private addHeader(
    startDate: string,
    endDate: string,
    pageWidth: number,
    margin: number,
    y: number,
  ): number {
    this.pdf.setFontSize(16);
    this.pdf.text("EXTRATO DA CONTA", margin, y);
    this.pdf.setFontSize(12);
    this.pdf.text(`${startDate} até ${endDate}`, pageWidth - margin, y, {
      align: "right",
    });
    y += 10;
    this.pdf.setDrawColor(0);
    this.pdf.line(margin, y, pageWidth - margin, y); // Linha horizontal
    y += 5;
    return y;
  }

  private addClientInfo(
    margin: number,
    y: number,
    color: string,
    pageWidth: number,
  ): number {
    const isPj = !!this.account?.companyInfoResponse?.name;
    const { name, document } =
      this.account.companyInfoResponse || this.account.personInfoResponse;
    this.pdf.setFontSize(10);
    this.pdf.setTextColor(color);
    this.pdf.text("Cliente", margin, y);
    y += 5;
    this.pdf.setTextColor(0);
    this.pdf.text(`Nome: ${name}`, margin, y);
    y += 5;
    if (isPj) {
      this.pdf.text(`CNPJ: ${formatCNPJ(document)}`, margin, y);
    } else {
      this.pdf.text(`CPF: ${formatCPF(document)}`, margin, y);
    }
    y += 5;
    this.pdf.text("Banco: 301 - Dock | Hyperlocal", margin, y);
    y += 5;
    this.pdf.text(`Agência: ${this.account.branch}`, margin, y);
    y += 5;
    this.pdf.text(
      `Conta: ${this.account.accountNumber} - ${this.account.accountDigit}`,
      margin,
      y,
    );
    y += 10;
    this.pdf.line(margin, y, pageWidth - margin, y); // Linha horizontal
    y += 5;
    return y;
  }

  private prepareTable(dates: ExportDates, data: PDFExportData[]) {
    const headers = [
      "Id",
      "Data",
      "Categoria",
      "Descrição",
      "Participante",
      "Tipo",
      "Valor (R$)",
    ];
    const pageWidth = this.pdf.internal.pageSize.getWidth();
    const margin = 10;
    let y = 10;
    const color = {
      avec: "#736EF5",
      crossX: "#001CA5",
      hyperlocal: "#0047f6",
    };
    y = this.addLogo(getVertical(), margin, y);
    y = this.addHeader(
      dates.startDate.toLocaleDateString(),
      dates.endDate.toLocaleDateString(),
      pageWidth,
      margin,
      y,
    );
    y = this.addClientInfo(margin, y, color[getVertical()], pageWidth);
    autoTable(this.pdf, {
      startY: y + 5,
      head: [headers],
      body: data.map((item) => Object.values(item)),
      theme: "grid",
      headStyles: {
        fillColor: color[getVertical()],
        cellWidth: "wrap",
      },
      styles: {
        cellPadding: 2,
        fontSize: 10,
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 30 }, // Id
        1: { cellWidth: "auto" }, // Data
        2: { cellWidth: "auto" }, // Categoria
        3: { cellWidth: "auto" }, // Descrição
        4: { cellWidth: "auto" }, // Participante
        5: { cellWidth: "auto" }, // Tipo
        6: { cellWidth: "auto" }, // Valor (R$)
      },
    });
    return this.pdf.output("blob");
  }

  async export(data: TransactionItem[], dates: ExportDates): Promise<void> {
    try {
      const transformedData = transformPDFStatementsResponse(data);
      const blob = this.prepareTable(dates, transformedData);
      // const blob = this.pdf.output("blob");
      const buffer = await blob.arrayBuffer();
      const base64 = btoa(
        new Uint8Array(buffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          "",
        ),
      );
      if (this.eventSender.getIsNative() && base64) {
        this.eventSender.sendMessage("FILE_DOWNLOAD", {
          base64,
          filename: "extrato.pdf",
        });
      } else {
        this.pdf.save("extrato.pdf");
      }
    } catch (error) {
      throw new Error(`Error exporting PDF: ${error.message}`);
    }
  }
}
export class CSVExporter implements ExportStrategy {
  private workbook: ExcelJs.Workbook;
  private worksheet: ExcelJs.Worksheet;
  private account: Account;
  constructor(private readonly eventSender: NativeEventSender<any>) {
    this.workbook = new ExcelJs.Workbook();
    this.worksheet = this.workbook.addWorksheet("Extrato");
    this.account = useAccountStore.getState().account;
  }

  private addAccountInfo(dates: ExportDates) {
    const isPj = !!this.account?.companyInfoResponse?.name;
    const { name, document } =
      this.account.companyInfoResponse || this.account.personInfoResponse;
    const documentText = isPj
      ? `CNPJ: ${formatCNPJ(document)} \n`
      : `CPF: ${formatCPF(document)} \n`;
    // Add bank header information in a single cell with line breaks
    const headerText =
      `Nome do Titular: ${name}\n` +
      "Banco: 301 - Dock | Hyperlocal \n" +
      `${documentText}` +
      `Agência: ${this.account.branch} \n` +
      `Conta: ${this.account.accountNumber} - ${this.account.accountDigit} \n` +
      `Período: ${dates.startDate.toLocaleDateString()} a ${dates.endDate.toLocaleDateString()}`;
    const headerRow = this.worksheet.addRow([headerText]);
    const headerCell = headerRow.getCell(1);
    this.worksheet.mergeCells(1, 1, 1, 5);
    headerCell.alignment = {
      vertical: "top",
      horizontal: "left",
      wrapText: true,
    };
    headerCell.font = { bold: true };
    headerCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE0E0E0" },
    };
    this.worksheet.getRow(1).height = 75;
    this.worksheet.getColumn(1).width = 50;
    // Add empty row for spacing
    this.worksheet.addRow([]);
  }

  private addTransactionTable(data: CSVExportData[]) {
    const tableHeaders = ["Data", "Tipo", "Descrição", "Entradas", "Saídas"];
    const tableHeaderRow = this.worksheet.addRow(tableHeaders);
    tableHeaderRow.font = { bold: true };
    this.worksheet.getColumn(1).width = 20; // Data
    this.worksheet.getColumn(2).width = 20; // Tipo
    this.worksheet.getColumn(3).width = 30; // Descrição
    this.worksheet.getColumn(4).width = 15; // Entradas
    this.worksheet.getColumn(5).width = 15; // Saídas
    data.forEach((row) => {
      this.worksheet.addRow([
        row.date,
        row.type,
        row.description,
        row.credit,
        row.debit,
      ]);
    });
    this.worksheet.getColumn(4).numFmt = "#,##0.00";
    this.worksheet.getColumn(5).numFmt = "#,##0.00";
  }

  async export(data: TransactionItem[], dates: ExportDates) {
    try {
      this.addAccountInfo(dates);
      const transformedData = transformCSVStatementsResponse(data);
      this.addTransactionTable(transformedData);
      const buffer = await this.workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (this.eventSender.getIsNative()) {
        const base64 = btoa(
          new Uint8Array(buffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            "",
          ),
        );
        this.eventSender.sendMessage("FILE_DOWNLOAD", {
          base64,
          filename: "extrato-bancario.xlsx",
        });
      } else {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "extrato-bancario.xlsx";
        link.click();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      throw new Error(`Error exporting CSV: ${error.message}`);
    }
  }
}
