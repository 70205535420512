import { nativeEventSender } from "@/utils";
import { CSVExporter, OFXExporter, PDFExporter } from "./file-types-exporters";
import { ExportStrategy, FileType } from "./types";

export class ExportStrategyFactory {
  static createStrategy(fileType: FileType): ExportStrategy {
    const exportMap: Record<FileType, ExportStrategy> = {
      csv: new CSVExporter(nativeEventSender),
      ofx: new OFXExporter(nativeEventSender),
      pdf: new PDFExporter(nativeEventSender),
    };
    return exportMap[fileType];
  }
}
