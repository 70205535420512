import { useCheckAppVersion } from "@/api/use-check-app-version";
import { CommitmentsDashboard } from "@/features/commitments";
import { LastTransactions, Statement } from "@/features/statements";
import { ParcelProps } from "@/types";
import { routes } from "@/utils";
import { Route, Routes as Switch } from "react-router-dom";

export const Routes = (props: ParcelProps) => {
  useCheckAppVersion();
  return (
    <Switch>
      {/* Rotas que usam o extrato */}
      <Route path="*" element={<LastTransactions {...props} />} />
      <Route path={routes.statements} element={<Statement />} />
      <Route path={routes.commitments} element={<CommitmentsDashboard />} />
    </Switch>
  );
};
